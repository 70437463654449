import {createContext, FC, ReactNode, useContext, useState} from "react";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { Navigate, Outlet, RouteProps } from "react-router-dom";
import axios from "axios";

export interface LoginRequest {
  kundennummer: string;
  password: string;
}

interface LoginResponse {
  uuid: string;
  token: string;
  vesper: boolean;
  fruehstueck: boolean;
  krippe: boolean;
}

export interface Kita {
  uuid: string;
  kundennummer: string;
  name: string;
  vesper: boolean;
  fruehstueck: boolean;
  krippe: boolean;
}

export interface IUseAuthProvider {
  login: (data: LoginRequest) => Promise<boolean>;
  logout: () => void;
  validate: () => void;
  user: Kita | null;
  loggedIn: boolean;
  validating: boolean;
}

interface UserResponse {
  fruehstueck: boolean;
  vesper: boolean;
  krippe: boolean;
  uuid: string;
}

interface JwtToken extends JwtPayload {
  name: string;
  kundennummer: string;
  uuid: string;
}

export const AuthContext = createContext({} as IUseAuthProvider);

const useAuthProvider = () => {
  const [user, setUser] = useState<Kita | null>(null);
  const [validating, setValidating] = useState<boolean>(true);
  const [loggedIn, setLoggedIn] = useState<boolean>(false);

  const validate = (): void => {
    setValidating(true);
    const token = localStorage.getItem("token");

    if (!token || typeof token === "undefined" || String(token).length <= 0) {
      localStorage.removeItem("token");
      setLoggedIn(false);
      setValidating(false);
      return;
    }

    const decoded: JwtToken = jwtDecode(token);
    if (decoded.exp && Date.now() >= decoded.exp * 1000) {
      localStorage.removeItem("token");
      setLoggedIn(false);
      setValidating(false);
      return;
    }

    axios
      .get<UserResponse>("/user")
      .then((response) => {
        const kita: Kita = {
          vesper: response.data.vesper,
          fruehstueck: response.data.fruehstueck,
          kundennummer: decoded.kundennummer,
          krippe: response.data.krippe,
          name: decoded.name,
          uuid: response.data.uuid,
        }
        setUser(kita);
      });

    setLoggedIn(true);
    setValidating(false);
  };

  const login = (data: LoginRequest): Promise<boolean> =>
    axios.post<LoginResponse>("/login", data).then((response) => {
      localStorage.setItem("token", response.data.token);
      const decoded: JwtToken = jwtDecode(response.data.token);
      const kita: Kita = {
        uuid: response.data.uuid,
        fruehstueck: response.data.fruehstueck,
        vesper: response.data.vesper,
        krippe: response.data.krippe,
        name: decoded.name,
        kundennummer: decoded.kundennummer,
      }
      setUser(kita);
      setLoggedIn(true);
      return true;
    });

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
    setLoggedIn(false);
  };

  return {
    user,
    login,
    logout,
    validate,
    validating,
    loggedIn,
  };
};

export const useAuth = () => useContext(AuthContext);

export const AuthProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export const PrivateRoute: FC<RouteProps> = () => {
  const auth = useAuth();

  if (auth.validating) return <div>Loading</div>;
  if (auth.loggedIn) return <Outlet />;
  return <Navigate to={"/login"} />;
};

export const PublicRoute: FC = () => {
  const auth = useAuth();

  if (auth.validating) return <div>Loading</div>;
  if (auth.loggedIn) return <Navigate to={"/"} />;
  else return <Outlet />;
};
