import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { LoginRequest, useAuth } from "../Auth";
import {
  Alert,
  AlertTitle,
  Button,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import logo from "../assets/ge-logo.png";

const Login = () => {
  const { register, handleSubmit } = useForm<LoginRequest>();
  const auth = useAuth();
  const [error, setError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");

  const submit = (data: LoginRequest) => {
    auth.login(data).catch((error) => {
      setError(true);
      if (error.response.data.statusCode === 401) {
        setErrorMessage("Kundennummer oder Passwort stimmen nicht überein");
      } else if (error.response.data.statusCode === 500) {
        setErrorMessage(
          "Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut!"
        );
      } else {
        setErrorMessage("Unbekannter Fehler");
      }

      setTimeout(() => {
        setError(false);
        setErrorMessage("");
      }, 3000);
    });
  };

  return (
    <Box
      sx={{
        maxWidth: 500,
        marginLeft: "50%",
        transform: "translateX(-50%)",
        mt: 5,
      }}
    >
      <Stack spacing={2} component="form" onSubmit={handleSubmit(submit)}>
        <img
          src={logo}
          style={{ maxWidth: 300, margin: "2rem auto 2rem auto" }}
          alt="logo"
        />
        <Typography component="h1" variant="h5" textAlign="center">
          Kita Bestellung
        </Typography>
        <TextField
          {...register("kundennummer")}
          required
          label="Kundennummer"
          fullWidth
        />
        <TextField
          {...register("password")}
          required
          type="password"
          fullWidth
        />
        <Button size="large" variant="contained" type="submit">
          einloggen
        </Button>
      </Stack>
      <Snackbar
        open={error}
        autoHideDuration={3000}
        onClose={() => setError(false)}
      >
        <Alert severity="error">
          <AlertTitle>Login fehlgeschlagen!</AlertTitle>
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Login;
