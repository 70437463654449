import React, { useEffect } from "react";
import { BrowserRouter, Routes, Route, NavLink } from "react-router-dom";
import { PrivateRoute, PublicRoute, useAuth } from "./Auth";
import Login from "./pages/Login";
import logo from "./assets/favicon.png";
import Bestellung from "./pages/Bestellung";
import Uebersicht from "./pages/Uebersicht";
import {
  Button,
  Container,
  CssBaseline,
  Divider,
  Stack,
  Typography,
  createTheme,
  ThemeProvider,
  styled,
} from "@mui/material";
import { Box } from "@mui/system";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "Inter",
    },
  },
});

const LogoutButton = styled(Button)(() => ({
  backgroundColor: "rgba(0,0,0,.12)",
  fontSize: 14,
  fontWeight: "bold",
  color: "rgba(0,0,0,.5)",
  textTransform: "none",
}));

const NavigationLink = styled(NavLink, {
  shouldForwardProp: (prop) => prop !== "selected",
})(() => ({
  fontWeight: "bold",
  fontSize: 14,
  textDecoration: "none",
  padding: ".3rem .5rem",
  color: "rgba(0,0,0,.5)",
  "&:hover": {
    color: "rgba(0,0,0,.6)",
  },
}));

function App() {
  const auth = useAuth();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => auth.validate(), []);

  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <main>
          <Container maxWidth="xl">
            <Stack spacing={2} sx={{ mt: 2 }}>
              {auth.loggedIn && (
                <React.Fragment>
                  <Stack spacing={2} direction="row" alignItems="center">
                    <NavigationLink
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                      to="/"
                    >
                      <Box
                        style={{
                          backgroundImage: "url(" + logo + ")",
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          width: 32,
                          height: 32,
                        }}
                      />
                      <span>Kita Bestellung</span>
                    </NavigationLink>
                    <NavigationLink to="/uebersicht">Übersicht</NavigationLink>
                    <Typography
                      variant="body1"
                      noWrap
                      sx={{
                        flexGrow: 1,
                        textAlign: "right",
                        fontSize: 14,
                        fontWeight: "bold",
                        color: "rgba(0,0,0,.6)",
                      }}
                    >
                      Willkommen, {auth.user !== null && auth.user.name}
                    </Typography>
                    <LogoutButton onClick={() => auth.logout()}>
                      ausloggen
                    </LogoutButton>
                  </Stack>
                  <Divider />
                </React.Fragment>
              )}
              <Routes>
                <Route path="/" element={<PrivateRoute />}>
                  <Route path="/" element={<Bestellung />} />
                  <Route path={"/uebersicht"} element={<Uebersicht />} />
                </Route>
                <Route path="/" element={<PublicRoute />}>
                  <Route path="/login" element={<Login />} />
                </Route>
              </Routes>
            </Stack>
          </Container>
        </main>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
