import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { AuthProvider } from "./Auth";
import axios from "axios";
import dayjs from "dayjs";
import de from "dayjs/locale/de";

dayjs.locale(de);

axios.defaults.baseURL =
  process.env.NODE_ENV === "development" ? "http://localhost:3333/kita" : "https://api.gutes-essen.bio/kita";
axios.interceptors.request.use(
  (config) => {
    if (config.headers) {
      config.headers.authorization = `Bearer ${localStorage.getItem("token")}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
