import React, { ChangeEvent, FC, useEffect, useMemo, useState } from "react";
import axios from "axios";
import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { useAuth } from "../Auth";
import "../styles/bestellung.scss";
import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardContent,
  CardHeader,
  List,
  ListItemButton,
  Skeleton,
  Snackbar,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import de from "dayjs/locale/de";

dayjs.locale(de);
dayjs.extend(weekOfYear);

export interface WebPortalItem {
  WebPortal_Category: string;
  ItemNo: string;
  ItemDescription: string;
  ItemDescription2: string;
  WebPortalDescription: string;
  PresentationOrder: 0 | 1;
  Quantity: number;
  Unif_of_Measure_Code: string;
  Unif_of_Measure: number | null;
  Qty_per_Unit_of_Measure_Code: string | null;
  Qty_per_Unit_of_Measure: number | null;
}

export interface CartDay {
  date: string;
  type: "fruehstueck" | "vesper" | "krippe";
  items: WebPortalItem[];
}

const Bestellung = () => {
  const auth = useAuth();
  const [week, setWeek] = useState<number>(dayjs().add(2, "weeks").week());
  const [year, setYear] = useState<number>(
    dayjs().add(2, "weeks").week() === 52 ? dayjs().year() : dayjs().add(2, "weeks").year()
  );
  const [webportalItems, setWebportalItems] = useState<WebPortalItem[]>([]);
  const [webportalItemsLoading, setWebportalItemsLoading] = useState<boolean>(false);
  const [webportalItemsError, setWebportalItemsError] = useState<boolean>(false);
  const [date, setDate] = useState<dayjs.Dayjs | null>(null);
  const [pickable, setPickable] = useState<boolean>(false);
  const [cart, setCart] = useState<CartDay[]>([]);
  const [search, setSearch] = useState<string>("");
  const [fv, setFV] = useState<"fruehstueck" | "vesper" | "krippe" | null>(null);
  const [showSuccess, setShowSuccess] = useState<boolean>(false);
  const [showError, setShowError] = useState<boolean>(false);

  const groupBy = (array: any[], key: string): any => {
    return array.reduce((result, currentValue) => {
      (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
      return result;
    }, {});
  };

  const filteredItems = useMemo(() => {
    return groupBy(
      webportalItems
        .filter((item) => {
          if (fv === null) return false;
          if ((fv === "fruehstueck" || fv === "vesper") && item.WebPortal_Category.startsWith("KITA-")) return true;
          return fv === "krippe" && item.WebPortal_Category.startsWith("KRIP-");
        })
        .filter((item) => {
          if (!search.length) return true;
          const s = search.toLowerCase();
          return (
            item.ItemDescription.toLowerCase().indexOf(s) > -1 ||
            item.ItemDescription2.toLowerCase().indexOf(s) > -1 ||
            item.WebPortal_Category.toLowerCase().indexOf(s) > -1 ||
            item.WebPortalDescription.toLowerCase().indexOf(s) > -1
          );
        }),
      "WebPortal_Category"
    );
  }, [webportalItems, search, fv]);

  useEffect(() => {
    let cancel = false;

    setWebportalItemsLoading(true);
    axios
      .get<WebPortalItem[]>("/webportalitems")
      .then((response) => {
        if (!cancel) setWebportalItems(response.data);
      })
      .catch(() => {
        setWebportalItemsError(true);
      })
      .finally(() => {
        if (!cancel) {
          setWebportalItemsLoading(false);
        }
      });

    return () => {
      cancel = true;
    };
  }, []);

  useEffect(() => {
    let cancel = false;
    axios
      .get<CartDay[]>("/orders?week=" + week + "&year=" + year)
      .then((response) => {
        if (!cancel) {
          setCart(response.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    return () => {
      cancel = true;
    };
  }, [week, year]);

  const isItemSelected = (item: WebPortalItem): boolean => {
    if (!date || fv === null) return false;
    const day = cart.find((day) => date.isSame(day.date, "day") && day.type === fv);
    if (day) {
      for (let i = 0; i < day.items.length; i++) {
        if (day.items[i].WebPortalDescription === item.WebPortalDescription) return true;
      }
    }
    return false;
  };
  const isItemOrdered = (item: WebPortalItem): boolean => {
    for (let i = 0; i < cart.length; i++) {
      for (let x = 0; x < cart[i].items.length; x++) {
        if (cart[i].items[x].WebPortalDescription === item.WebPortalDescription) return true;
      }
    }
    return false;
  };

  const handleWeekClick = (w: number, y: number) => {
    if (w === week) return;
    setWeek(w);
    setYear(y);
    setDate(null);
    setPickable(false);
    setSearch("");
  };

  const handleDateClick = (day: dayjs.Dayjs) => {
    if (date && date.isSame(day, "day")) return;
    setDate(day);
    if (fv !== null) {
      setPickable(true);
    }
  };

  const handleItemClick = (item: WebPortalItem) => {
    if (!date || fv === null) return;
    if (item.PresentationOrder === 0) return;
    const copy = [...cart];

    for (let i = 0; i < copy.length; i++) {
      if (date.isSame(copy[i].date, "day") && copy[i].type === fv) {
        for (let x = 0; x < copy[i].items.length; x++) {
          if (copy[i].items[x].WebPortalDescription === item.WebPortalDescription) {
            copy[i].items.splice(x, 1);
            setCart(copy);
            return;
          }
        }
        copy[i].items.push(item);
        setCart(copy);
        return;
      }
    }

    const cartDay = {
      date: date.format("YYYY-MM-DD"),
      type: fv,
      items: [item],
    };
    copy.push(cartDay);
    setCart(copy);
  };

  const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.currentTarget.value);
  };

  const handleFVClick = (type: "fruehstueck" | "vesper" | "krippe") => {
    setFV(type);
    if (!pickable && date !== null) setPickable(true);
  };

  const handleSubmitOrder = () => {
    if (!auth.user) return;
    setPickable(false);
    const data = {
      days: cart,
    };
    axios
      .post("/orders", data)
      .then(() => {
        setShowSuccess(true);
        setTimeout(() => {
          setPickable(true);
        }, 3000);
      })
      .catch(() => setShowError(true));
  };

  const printGroupTitle = (title: string): string => {
    if (title === "KITA-BACK" || title === "KRIP-BACKW") return "Backwaren";
    if (title === "KITA-CEREA") return "Cerealien";
    if (title === "KITA-GEMUE" || title === "KRIP-GEMUE") return "Gemüse";
    if (title === "KITA-HERZH" || title === "KRIP-HERZH") return "Herzhafte Beilage";
    if (title === "KITA-OBST" || title === "KRIP-OBST") return "Obst";
    if (title === "KITA-SUESB" || title === "KRIP-SUESB") return "Süßer Belag";
    if (title === "KITA-SUESS" || title === "KRIP-SUESS") return "Süßes";
    if (title === "KITA-WURST") return "Wurst";
    return "";
  };

  const formatItemQuantity = (item: WebPortalItem): string => {
    let measure = "";
    if (item.Unif_of_Measure_Code === "SCH") {
      if (item.Quantity > 1) {
        measure = "Scheiben";
      } else {
        measure = "Scheibe";
      }
    } else if (item.Unif_of_Measure_Code === "ST") {
      measure = "Stück";
    } else if (item.Unif_of_Measure_Code === "PORTKITA" || item.Unif_of_Measure_Code === "PORTKRIPPE") {
      if (item.Quantity > 1) {
        measure = "Portionen";
      } else {
        measure = "Portion";
      }
    }
    return item.Quantity + " " + measure + " pro Kind";
  };

  const Item: React.FC<{ item: WebPortalItem }> = ({ item }) => (
    <Card
      className={"item" + (isItemSelected(item) ? " selected" : "") + (isItemOrdered(item) ? " ordered" : "")}
      onClick={() => handleItemClick(item)}
      sx={{ maxWidth: 300, width: "100%" }}
    >
      <CardHeader sx={{ padding: ".2rem" }} title={item.WebPortalDescription.split(" - ")[0]} />
      <CardContent sx={{ padding: ".2rem" }}>
        <p style={{ margin: 0, fontSize: 13, color: "rgba(0,0,0,.7)" }}>{formatItemQuantity(item)}</p>
      </CardContent>
    </Card>
  );

  const ItemGroup: FC<{ group: string }> = ({ group }) => (
    <div className="group">
      <Typography component="h1" variant="body1" className="groupTitle">
        {printGroupTitle(group)}
      </Typography>
      <div className="items">
        {filteredItems[group] &&
          filteredItems[group].map((item: WebPortalItem, index: number) => <Item item={item} key={index} />)}
      </div>
    </div>
  );

  const weeks = useMemo(() => {
    const weeks = [];
    for (let i = 2; i < 6; i++) {
      const date = dayjs().add(i, "weeks");
      weeks.push({
        w: date.week(),
        y: date.week() === 52 ? date.year() - 1 : date.year(),
      });
    }
    return weeks;
  }, []);

  const weekdays = useMemo(() => {
    const days = [];
    const monday = dayjs().year(year).week(week).startOf("week");
    for (let i = 0; i < 5; i++) {
      days.push(monday.add(i, "days"));
    }
    return days;
  }, [week, year]);

  return (
    <Stack spacing={2} direction="row" style={{ position: "relative" }}>
      <Stack
        style={{
          minWidth: 300,
          flexGrow: 0,
          position: "sticky",
          top: 0,
          left: 0,
          height: "100vh",
          paddingTop: "1rem",
        }}
        spacing={1}
      >
        <Typography variant="body2">Kalenderwoche wählen</Typography>
        <List>
          {weeks.map((kw, index) => (
            <ListItemButton selected={week === kw.w} key={index} onClick={() => handleWeekClick(kw.w, kw.y)}>
              KW {kw.w}, {kw.y}
            </ListItemButton>
          ))}
        </List>
        <Typography variant="body2">Komponenten wählen</Typography>
        <List>
          {auth.user && auth.user.fruehstueck && (
            <ListItemButton selected={fv === "fruehstueck"} onClick={() => handleFVClick("fruehstueck")} key={0}>
              Frühstück
            </ListItemButton>
          )}
          {auth.user && auth.user.vesper && (
            <ListItemButton selected={fv === "vesper"} onClick={() => handleFVClick("vesper")} key={1}>
              Vesper
            </ListItemButton>
          )}
          {auth.user && auth.user.krippe && (
            <ListItemButton selected={fv === "krippe"} onClick={() => handleFVClick("krippe")} key={2}>
              Krippe
            </ListItemButton>
          )}
        </List>
        <Typography variant="body2">Wochentag wählen</Typography>
        <List>
          {weekdays.map((day, index) => (
            <ListItemButton
              selected={date !== null && date.isSame(day, "day")}
              key={index}
              onClick={() => handleDateClick(day)}
            >
              {day.format("dddd DD.MM.YY")}
            </ListItemButton>
          ))}
        </List>
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        <Stack spacing={2}>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              position: "sticky",
              top: 0,
              right: 0,
              bgcolor: "#fff",
              p: "1rem",
              marginLeft: "-2px",
              width: "calc(100% + 2px)",
            }}
          >
            <TextField
              sx={{ flexGrow: 1 }}
              value={search}
              placeholder="Artikel suchen..."
              disabled={!pickable}
              onChange={handleSearchChange}
            />
            <Button variant="contained" disabled={!pickable} onClick={() => handleSubmitOrder()}>
              bestellung senden
            </Button>
          </Stack>
          <Typography component="h2" variant="h4">
            {fv !== null && fv === "fruehstueck" && "Frühstücksartikel"}
            {fv !== null && fv === "vesper" && "Vesperartikel"}
            {fv !== null && fv === "krippe" && "Krippenartikel"}
          </Typography>
          {webportalItemsLoading && (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: "row",
                gap: "1rem",
              }}
            >
              <Skeleton variant="rectangular" width={120} height={120} />
              <Skeleton variant="rectangular" width={120} height={120} />
              <Skeleton variant="rectangular" width={120} height={120} />
              <Skeleton variant="rectangular" width={120} height={120} />
              <Skeleton variant="rectangular" width={120} height={120} />
              <Skeleton variant="rectangular" width={120} height={120} />
              <Skeleton variant="rectangular" width={120} height={120} />
              <Skeleton variant="rectangular" width={120} height={120} />
              <Skeleton variant="rectangular" width={120} height={120} />
              <Skeleton variant="rectangular" width={120} height={120} />
              <Skeleton variant="rectangular" width={120} height={120} />
              <Skeleton variant="rectangular" width={120} height={120} />
            </Box>
          )}
          {!webportalItemsLoading && webportalItemsError && <h1>Fehler beim Laden der Artikel</h1>}
          {!webportalItemsLoading && !webportalItemsError && (
            <div className={"webportalitems" + (pickable ? " pickable" : "")} style={{ marginBottom: "3rem" }}>
              {filteredItems &&
                Object.keys(filteredItems).map((group: string, index: number) => (
                  <ItemGroup group={group} key={index} />
                ))}
            </div>
          )}
        </Stack>
      </Box>
      <Snackbar open={showSuccess} autoHideDuration={3000} onClose={() => setShowSuccess(false)}>
        <Alert severity="success">
          <AlertTitle>Erfolg!</AlertTitle>
          Ihre Bestellung wurde <strong>erfolgreich</strong> gespeichert.
        </Alert>
      </Snackbar>
      <Snackbar open={showError} autoHideDuration={3000} onClose={() => setShowError(false)}>
        <Alert severity="error">
          <AlertTitle>Fehler!</AlertTitle>
          Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später noch einmal.
        </Alert>
      </Snackbar>
    </Stack>
  );
};

export default Bestellung;
