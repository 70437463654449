import React, { useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import de from "dayjs/locale/de";
import isLeapYear from "dayjs/plugin/isLeapYear";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeeksInYear from "dayjs/plugin/isoWeeksInYear";
import axios from "axios";
import { CartDay } from "./Bestellung";
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import fileDownload from "js-file-download";

dayjs.locale(de);
dayjs.extend(isLeapYear);
dayjs.extend(weekOfYear);
dayjs.extend(isoWeeksInYear);

const Uebersicht = () => {
  const [week, setWeek] = useState<number>(dayjs().week() + 2);
  const [year, setYear] = useState<number>(
    dayjs().add(2, "weeks").week() === 52 ? dayjs().year() : dayjs().add(2, "weeks").year()
  );
  const [selectedWeek, setSelectedWeek] = useState<string>(week + "-" + year);
  const [cart, setCart] = useState<CartDay[]>([]);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);

  useEffect(() => {
    let cancel = false;
    if (typeof week !== "undefined") {
      axios.get<CartDay[]>("/orders?week=" + week + "&year=" + year).then((response) => {
        if (!cancel) {
          setCart(response.data);
        }
      });
    }
    return () => {
      cancel = true;
    };
  }, [week, year]);

  const handleWeekChange = (e: SelectChangeEvent) => {
    try {
      const w = parseInt(e.target.value.split("-")[0]);
      const y = parseInt(e.target.value.split("-")[1]);
      setWeek(w);
      setYear(y);
      setSelectedWeek(w + "-" + y);
    } catch {
      const w = dayjs().week() + 2;
      const y = dayjs()
        .week(dayjs().week() + 2)
        .year();
      setWeek(w);
      setYear(y);
      setSelectedWeek(w + "-" + y);
    }
  };

  const print = () => {
    setDownloadLoading(true);
    axios
      .get("/wochenplan?year=" + year + "&week=" + week, {
        responseType: "blob",
      })
      .then((response) => {
        const disposition = response.headers["content-disposition"];
        if (disposition) {
          const startIndex = disposition.indexOf('"') + 1;
          const endIndex = disposition.lastIndexOf('"');
          const filename = disposition.substring(startIndex, endIndex);
          fileDownload(response.data, filename, "application/pdf");
        }
      })
      .finally(() => setDownloadLoading(false));
  };

  const printItemName = (title: string): string => {
    return title.split(" - ")[0];
  };

  const weeks = useMemo(() => {
    const weeks = [];
    for (let i = 2; i < 6; i++) {
      const date = dayjs().add(i, "weeks");
      weeks.push({
        w: date.week(),
        y: date.week() === 52 ? date.year() - 1 : date.year(),
      });
    }
    return weeks;
  }, []);

  const weekdays = useMemo(() => {
    const days = [];
    const monday = dayjs().year(year).week(week).startOf("week");
    for (let i = 0; i < 5; i++) {
      days.push(monday.add(i, "days"));
    }
    return days;
  }, [week, year]);

  return (
    <Stack spacing={2}>
      <Typography component="h1" variant="h4">
        Ihre Bestellübersicht
      </Typography>
      <Stack spacing={2} direction="row">
        <FormControl sx={{ width: 200 }}>
          <InputLabel id="week-label">Kalenderwoche</InputLabel>
          <Select
            labelId="week-label"
            id="week-select"
            value={selectedWeek}
            label="Kalenderwoche"
            onChange={handleWeekChange}
          >
            {weeks.map((week, index) => (
              <MenuItem sx={{ pt: 2, pb: 2 }} key={index} value={week.w + "-" + week.y}>
                KW {week.w}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <div style={{ flexGrow: 1 }} />
        <LoadingButton
          loading={downloadLoading}
          size="large"
          sx={{ minWidth: 200 }}
          variant="contained"
          onClick={print}
          disabled={typeof week === "undefined"}
        >
          Wochenplan herunterladen
        </LoadingButton>
      </Stack>
      <div id="wrapper">
        <Grid container spacing={2} columns={10}>
          {weekdays.map((weekday, index) => (
            <Grid item sm={12} md={2} key={index}>
              <Card>
                <CardHeader title={weekday.format("dddd DD.MM.YYYY")} />
                <CardContent>
                  <List>
                    <ListSubheader>Frühstück</ListSubheader>
                    {cart
                      .find((day) => day.date === weekday.format("YYYY-MM-DD") && day.type === "fruehstueck")
                      ?.items.map((item, index) => (
                        <ListItem key={index}>{printItemName(item.WebPortalDescription)}</ListItem>
                      ))}
                    <ListSubheader>Vesper</ListSubheader>
                    {cart
                      .find((day) => day.date === weekday.format("YYYY-MM-DD") && day.type === "vesper")
                      ?.items.map((item, index) => (
                        <ListItem key={index}>{printItemName(item.WebPortalDescription)}</ListItem>
                      ))}
                  </List>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </div>
    </Stack>
  );
};

export default Uebersicht;
